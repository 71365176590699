import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect () {
    const from = this.params.get("from")?.trim();

    if (window.bot || !from) {
      this.element.hidden = false;
    } else {
      window.location = from;
    }
  }

  get params () {
    if (!this._params) {
      this._params = new URLSearchParams(window.location.search);
    }

    return this._params;
  }
}
