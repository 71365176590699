import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["iframe"];
  static values = { origin: String };

  connect() { }

  pause(event = undefined) {
    const pause = true;

    for (const iframe of this.iframeTargets) {
      iframe.contentWindow.postMessage({ pause }, this.originValue);
    }
  }
}
