import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["popup"];

  connect() {
    const possiblePopup = window.location.hash?.slice(1);

    if (possiblePopup?.length === 0) return;

    const togglerId = `${possiblePopup}-toggler`;
    const toggler = this.popupTargets.find(x => x.id === togglerId);

    if (!toggler) {
      console.error("No se pudo encontrar toggler", togglerId);
      return;
    }

    if ("checked" in toggler) toggler.checked = true;
  }
}
