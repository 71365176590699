import { Controller } from "@hotwired/stimulus";

import L from "leaflet";

export default class extends Controller {
  static targets = ["map", "point", "svgOverlay", "help", "buttonHelp"];
  static values = {
    videoOverlay: String,
    imageOverlay: String,
    boundY: Number,
    boundX: Number
  };

  connect() {
    this.layer;
    this.visiting = false;
    this.map.setMaxBounds([[68, -175],[-68, 175]]);
    this.invalidateSize();
  }

  invalidateSize(event = undefined) {
    this.map.invalidateSize();
  }

  pointTargetConnected(point) {
    const coords = this.coords_from(point);

    if (!coords) return;

    const marker = this.marker(coords, point);

    marker._icon.innerHTML = point.innerHTML;
    marker._icon.dataset.height = point.dataset.height;
    marker._icon.dataset.width = point.dataset.width;

    if ('touchable' in point.dataset) {
      marker.on('click', event => {
        if (this.visiting) return;

        this.visiting = true;
        Turbo.visit(point.dataset.url);
      });
    } else {
      marker._icon.classList.add("cursor-hand", "pointer-event-none");
      marker._icon.classList.remove("leaflet-interactive");
      marker._icon.removeAttribute("tabindex");
      marker._icon.removeAttribute("title");
    }

    this.markers[point.id] = {
      marker,
      timeout: setTimeout(() => {
        marker._icon.classList.remove("o-0");
        marker._icon.classList.add("o-5");
      }, 1000)
    };
  }

  pointTargetDisconnected(point) {
    const marker = this.markers[point.id];

    if (!marker) return;

    this.group.removeLayer(marker.marker);
    clearTimeout(marker.timeout);
  }

  disconnect() {
    this.map.removeLayer(this.layer);
  }

  get markers() {
    if (!this._markers) this._markers = {};

    return this._markers;
  }

  /*
   * Obtener las coordenadas del mapa, por defecto el centro de CABA (!)
   *
   * @return [Array]
   */
  get coords() {
    const c = this.coords_from(this.element);

    return c ? c : [0, 0];
  }

  // @return [Integer]
  get zoom() {
    const z = parseInt(this.element.dataset.zoom);

    return isNaN(z) ? 11 : z;
  }

  get group() {
    if (!this._group) {
      this._group = L.featureGroup();
      this._group.addTo(this.map);
    }

    return this._group;
  }

  get map() {
    if (!this._map) {
      this._map = L.map(this.mapTarget, {
        zoom: 3,
        minZoom: 0,
        maxZoom: 5,
        attributionControl: false,
      }).setView([0,0], 3);;

      this._map.on("zoomend", (event) => {
        const zoom = this.map.getZoom();
        let ratio = zoom / 3;
        if (ratio === 0) ratio = 0.1;

        for (const markerId in this.markers) {
          const marker = this.markers[markerId].marker;
          const icon = marker._icon;

          icon.style.width = `${icon.dataset.width * ratio}px`;
          icon.style.height = `${icon.dataset.height * ratio}px`;
          icon.style.marginLeft = `${(icon.dataset.width / 2) * ratio * -1}px`;
          icon.style.marginTop = `${icon.dataset.height * ratio * -1}px`;
        }
      });
    }

    return this._map;
  }

  get layer() {
    if (!this._layer) {
      this._layer = L.tileLayer("public/map/{z}/{y}/{x}.png", {
        minNativeZoom: 0,
        maxNativeZoom: 5,
        noWrap: true
      }).addTo(this.map);
    }

    return this._layer;
  }

  icon(point) {
    const width = parseInt(point.dataset.width);
    const height = parseInt(point.dataset.height);

    return L.divIcon({
      className: "transition o-0",
      iconSize: [width, height],
      iconAnchor: [width / 2, height],
      html: "<div></div>"
    });
  }

  /*
   * Obtener coordenadas del dataset de un elemento, si alguna no es
   * válida devuelve undefined
   *
   * @return [Array,undefined]
   */
  coords_from(element) {
    const lat = parseFloat(element.dataset.lat);
    const lng = parseFloat(element.dataset.lng);

    if (isNaN(lat) || isNaN(lng)) return undefined;

    return [lat, lng];
  }

  marker(coords, point) {
    return L.marker(coords, { icon: this.icon(point), title: point.dataset.title }).addTo(this.group);
  }

  toggleHelp(event) {
    if (this.helpTarget.hidden) {
      this.hide(this.buttonHelpTarget);
      this.show(this.helpTarget);
    } else {
      this.show(this.buttonHelpTarget);
      this.hide(this.helpTarget);
    }
  }

  show(element) {
    element.hidden = false;
    element.classList.remove("o-0");
    element.classList.add("o-5");
  }

  hide(element) {
    element.classList.remove("o-5");
    element.classList.add("o-0");

    setTimeout(() => element.hidden = true, 300);
  }
}
