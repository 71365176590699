import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["toggler"];

  /*
   * Al encender un toggler se apagan todos los demás
   */
  change(event = undefined) {
    if (!event.target.checked) return;

    for (const toggler of this.togglerTargets) {
      if (toggler == event.target) continue;

      toggler.checked = false;
    }
  }
}
